import { Frequency, RRule, Weekday } from "rrule";
import { ScheduleCreateForm } from "./scheduler";

export function simpleFreqToRRule(
  startDate: Date | undefined,
  frequencyPresetString: ScheduleCreateForm["frequencySimplified"],
) {
  if (!startDate) {
    return undefined;
  }

  switch (frequencyPresetString) {
    case "not-repeat":
      return new RRule({
        dtstart: startDate,
        freq: Frequency.DAILY,
        count: 1,
      });
    case "daily":
      return dailyFreq(startDate);
    case "weekly":
      return weeklyDowFreq(startDate);
    case "monthly":
      return monthlyOnFirstDowFreq(startDate);
    case "yearly":
      return yearlyMonthDateFreq(startDate);
    case "weekday":
      return weekdayFreq(startDate);
    case "custom":
      return undefined;
  }
}

function dailyFreq(dt: Date) {
  return new RRule({
    dtstart: dt,
    freq: Frequency.DAILY,
  });
}

function weeklyDowFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.WEEKLY,
    byweekday: wd,
  });
}

function monthlyOnFirstDowFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.MONTHLY,
    byweekday: wd,
  });
}

function yearlyMonthDateFreq(dt: Date) {
  const wd = new Weekday(dt.getDay() - 1);
  return new RRule({
    dtstart: dt,
    freq: Frequency.YEARLY,
    bymonth: dt.getMonth(),
    byweekday: wd,
  });
}

function weekdayFreq(dt: Date) {
  return new RRule({
    dtstart: dt,
    freq: Frequency.WEEKLY,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  });
}

type SimpleFreqValue = ScheduleCreateForm["frequencySimplified"];

export function recurrenceToSimplifiedFreq(
  startDate: Date,
  rule: RRule,
): SimpleFreqValue {
  // Generate inverse to see if the rrules match
  switch (rule.options.freq) {
    case Frequency.DAILY: {
      const _simple = simpleFreqToRRule(startDate, "daily");
      if (_simple?.toString() === rule.toString()) {
        return "daily";
      }
      const _simpleNotRepeat = simpleFreqToRRule(startDate, "not-repeat");
      if (_simpleNotRepeat?.toString() === rule.toString()) {
        return "not-repeat";
      }
      return "custom";
    }
    case Frequency.WEEKLY: {
      const _simple = simpleFreqToRRule(startDate, "weekly");
      if (_simple?.toString() === rule.toString()) {
        return "weekly";
      }
      const _simpleWeekday = simpleFreqToRRule(startDate, "weekday");
      if (_simpleWeekday?.toString() === rule.toString()) {
        return "weekday";
      }
      return "custom";
    }
    case Frequency.MONTHLY: {
      const _simple = simpleFreqToRRule(startDate, "monthly");
      if (_simple?.toString() === rule.toString()) {
        return "monthly";
      }
      return "custom";
    }
    case Frequency.YEARLY: {
      const _simple = simpleFreqToRRule(startDate, "yearly");
      if (_simple?.toString() && rule.toString()) {
        return "yearly";
      }
      return "custom";
    }
    default: {
      return "custom";
    }
  }
}
