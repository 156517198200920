import { ADMIN, SUPER_ADMIN, MANAGE_PEOPLE } from "../permission.maps";

// types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TWorkflow } from "../typings";

export const DefaultTrainingWorkflow: TWorkflow = {
  _meta: {
    start: "completed",
    end: "completed",
  },
  completed: {
    name: "Completed",
    _meta: {
      nodeGuards: {
        permissions: [ADMIN.name, SUPER_ADMIN.name, MANAGE_PEOPLE.name],
      },
    },
  },
};

export const DefaultEquipmentWorkflow: TWorkflow = {
  _meta: {
    start: "done",
    end: "done",
  },
  done: {
    name: "Done",
    prev: ["done"],
  },
};

export const DefaultApprovalWorkflow: TWorkflow = {
  _meta: {
    start: "in_prog",
    end: "approved",
  },
  in_prog: {
    name: "In Progress",
    next: ["in_review"],
  },
  in_review: {
    name: "Under Review",
    prev: ["in_prog"],
    next: ["approved"],
    _meta: {
      nodeGuards: {
        permissions: [ADMIN.name, SUPER_ADMIN.name],
        stateAssignee: ["in_prog"],
        roles: [],
        userIds: [],
      },
    },
  },
  approved: {
    name: "Approved",
    prev: ["in_review"],
    _meta: {
      nodeGuards: {
        permissions: [ADMIN.name, SUPER_ADMIN.name],
        roles: [],
        userIds: [],
      },
    },
  },
};

export const DefaultWorkflow: TWorkflow = {
  _meta: {
    start: "todo",
    end: "done",
  },
  todo: {
    name: "To-Do",
    next: ["in_prog"],
  },
  in_prog: {
    name: "In Progress",
    prev: ["todo"],
    next: ["done"],
  },
  done: {
    name: "Done",
    prev: ["in_prog"],
  },
};
