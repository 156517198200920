// form Definitions
import { systemNameField } from "./field-schema-form";

// types
import { IFieldGroupBuilt, IFieldTypes } from "../../typings";

export const EquipmentSettingDefinition: IFieldGroupBuilt = {
  fields: [
    {
      fieldType: IFieldTypes.textField,
      name: "title",
      label: "Title",
      required: true,
      span: 2,
    },

    {
      fieldType: IFieldTypes.textArea,
      name: "metadata.description",
      label: "Description",
      max: 5000,
      defaultValue: "",
      span: 4,
    },
    {
      ...systemNameField,
      readonly: true,
      description: "The System name is a unique identifier for the form",
    },
  ],
  min: 1,
  max: 1,
  label: "FieldGroups",
  name: "FieldGroups",
};
