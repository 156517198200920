// libraries
import { Timestamp } from "@google-cloud/firestore";
import { Frequency, RRule, Weekday, WeekdayStr, rrulestr } from "rrule";

// utilities
import {
  Duration,
  dateOrTimestampAsDate,
  dueDateFormToDuration,
  durationToQuantity,
  durationToUnit,
  isDate,
} from "../../utilities/time";
import { isObject } from "../../utilities";

// types
import { RRuleOptions, Schedule } from "./scheduler-core";
import {
  SchemasReferencesRecord,
  recurrenceToSimplifiedFreq,
  simpleFreqToRRule,
} from "../../typings";
import dayjs from "dayjs";

export type DateFixed = {
  dtType: "fixed";
  dateTime: Date | Timestamp;
};

export type DateRelative = {
  dtType: "relative";
  duration: Duration;
};

// FixedOrRelative
export type DateFixedOrRelative = DateFixed | DateRelative;

export function isFixedDate(
  dueDate: DateFixedOrRelative,
): dueDate is DateFixed {
  if (!isObject(dueDate)) {
    return false;
  }

  const typ = dueDate.dtType;
  if (typ !== "fixed") {
    return false;
  }
  const vIsDate = isDate(dateOrTimestampAsDate(dueDate.dateTime));
  return typ === "fixed" && vIsDate;
}

export function isRelativeDate(
  dueDate: DateFixedOrRelative,
): dueDate is DateRelative {
  if (!isObject(dueDate)) {
    return false;
  }

  const typ = dueDate.dtType;

  if (typ !== "relative") {
    return false;
  }

  return typ === "relative" && typeof dueDate["duration"] === "object";
}

export type ScheduleFormPayload = {
  assignedTo?: string[];
  assignedToRole?: string;
  formPerUser?: boolean;
  form: {
    schemaId: string;
  };
  dueDate?: DateRelative;
};

// We don't explicitly type the taskPayload in schedule-core because the core types are agnostic
// of the contents of the payload. We type it here as an end user of the scheduling system.
export type ScheduleType = Schedule & {
  // Limit the task payload to specific types
  taskPayload: ScheduleFormPayload;
};

type EndsType = "never" | "on" | "after";

export type ScheduleCreateForm = Omit<
  ScheduleType,
  "id" | "recurrence" | "paused" | "pausedUntil" | "startDate"
> & {
  startDate?: string; // Input from the frontend will be a Date
  _timeOfDay: string;
  _recurrence?: Omit<
    RRuleOptions,
    "bymonth" | "byweekday" | "bysetpos" | "byhour" | "tzid" | "until"
  > & {
    bymonth?: string[];
    byweekday?: string[];
    bysetpos?: number;
    until?: string;

    _ends?: EndsType;

    _weekday?: string[];
  };

  _dueDate: "none" | "overdue-after";
  _dueDateDuration?: number;
  /**
   * "min" is only allowed in dev environments
   */
  _dueDateUnit?: "h" | "d" | "w" | "m" | "y";

  _assignType?: "user-each" | "user-shared" | "role-each" | "role-shared";
};

export type ScheduleEditForm = Omit<
  ScheduleCreateForm,
  "id" | "startDate" | "timeZone" | "pastScheduledRuns"
>;

export type AllScheduleReferenceMetadata = Pick<
  ScheduleType,
  | "id"
  | "name"
  | "startDate"
  | "end"
  | "taskPayload"
  | "recurrence"
  | "paused"
  | "pausedUntil"
  | "nextScheduledDate"
  | "taskType"
  | "frequencySimplified"
>;

export type AllSchedulesRecord =
  SchemasReferencesRecord<AllScheduleReferenceMetadata>;

const monthToIndex: Record<string, number> = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

// Add 1 to get the 1 based index of the months
const indexToMonth: string[] = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

/**
 * Utility functions
 *
 * @todo move these to the utils
 */

export function recurrenceSectionToRRule(
  startDate: string | undefined,
  recurrence: ScheduleCreateForm["_recurrence"],
): RRule | undefined {
  if (!recurrence) {
    return;
  }
  const opts = structuredClone(recurrence);
  opts.freq = parseInt(opts.freq?.toString());
  if (isNaN(opts.freq)) {
    return;
  }

  // const months: string[] | undefined = recurrence.bymonth;
  let bymonth: number[] | null = null;
  if (opts.bymonth) {
    bymonth = opts.bymonth.map((m) => monthToIndex[m]);
    if (bymonth.length === 0) {
      bymonth = null;
    }
  }

  const byweekday =
    opts._weekday?.map((w) => Weekday.fromStr(w as WeekdayStr).weekday) || [];

  let bysetpos: number | null = opts.bysetpos || null;
  if (
    bysetpos !== null &&
    (bysetpos === 0 || bysetpos > 366 || bysetpos < -366)
  ) {
    bysetpos = null;
  }
  // const bySetPos: number | null = (!opts.bysetpos || opts.bysetpos === 0 || opts.bysetpos>366 || opts.bysetpos< -366) ? null : opts.bysetpos;

  const dtstart = startDate;
  if (dtstart) {
    opts.dtstart = new Date(dtstart);
  }

  const until = opts.until ? new Date(opts.until) : undefined;
  const rule = new RRule({
    freq: opts.freq,
    interval: opts.interval,
    count: opts.count,
    dtstart: opts.dtstart,
    byweekno: opts.byweekno,
    bymonthday: opts.bymonthday,
    until,
    bymonth,
    byweekday,
    bysetpos,
  });
  return rule;
}

// TODO: Test new schedule creation with weekday set and view in edit schedule
function rruleToRecurrenceSection(
  rule: RRule,
): ScheduleCreateForm["_recurrence"] {
  const bymonth = rule.options.bymonth?.map((m) => indexToMonth[m - 1]) || [];
  const byweekday = rule.options.byweekday?.map((w) => w.toString()) || [];
  const bysetpos =
    Array.isArray(rule.options.bysetpos) && rule.options.bysetpos.length
      ? rule.options.bysetpos[0]
      : undefined;

  const opts = rule.options;
  let _ends: EndsType = "never";
  if (opts.count) {
    _ends = "after";
  } else if (opts.until) {
    _ends = "on";
  }

  const until = opts.until ? opts.until.toISOString() : undefined;

  return {
    _ends: _ends,
    _weekday: opts.byweekday?.map((wd) => new Weekday(wd).toString()),
    freq: opts.freq,
    interval: opts.interval,
    count: opts.count,
    until: until,
    dtstart: opts.dtstart,
    byweekno: opts.byweekno,
    bymonthday: opts.bymonthday,
    byyearday: opts.byyearday,
    bymonth,
    byweekday,
    bysetpos,
  };
}

export function createScheduleFormToScheduleType(
  formValues: Partial<ScheduleCreateForm>,
): Omit<ScheduleType, "id"> | undefined {
  const _formValues = structuredClone(formValues);
  if (!_formValues.name || !_formValues.taskPayload) {
    return;
  }

  if (
    _formValues.frequencySimplified === "custom" &&
    !_formValues._recurrence
  ) {
    return;
  }

  const _startDate = _formValues.startDate;
  if (!_startDate) {
    return;
  }
  /*
   * Keep local time because we want to keep the same date the user selected and override the time
   */
  let startDate = dayjs(_startDate).tz(_formValues.timeZone, true);
  let todParsed = startDate;

  // Time of day here will be an ISO string, we want local time (what the user sees)
  //    to get the expected wall clock time.
  if (_formValues._timeOfDay) {
    todParsed = dayjs(_formValues._timeOfDay);
    const hour = todParsed.hour();
    const minute = todParsed.minute();
    // Override time with timeOfDay
    startDate = startDate.hour(hour).minute(minute);
  }

  let rrule: RRule | undefined;
  switch (_formValues.frequencySimplified) {
    case "not-repeat":
    case "daily":
    case "weekly":
    case "monthly":
    case "yearly":
    case "weekday": {
      rrule = simpleFreqToRRule(
        startDate.toDate(),
        _formValues.frequencySimplified,
      );
      break;
    }
    case "custom": {
      rrule = recurrenceSectionToRRule(
        startDate.toISOString(),
        _formValues._recurrence,
      );
    }
  }

  if (!rrule || !_formValues.timeZone) {
    return;
  }

  if (_formValues._dueDateUnit && _formValues._dueDateDuration) {
    _formValues.taskPayload.dueDate = dueDateFormToDuration(_formValues);
  }

  return {
    name: _formValues.name,
    startDate: _formValues.startDate ? startDate.toDate() : new Date(),
    end: _formValues.end,
    nextScheduledDate: null,
    pastScheduledRuns: [],
    timeZone: _formValues.timeZone,
    frequencySimplified: _formValues.frequencySimplified,
    recurrence: rrule.toString(),
    taskType: _formValues.taskType || "form",
    taskPayload: _formValues.taskPayload,
    paused: false,
    pausedUntil: null,
  };
}

export function scheduleTypeToForm(schedule: ScheduleType): ScheduleCreateForm {
  // Parse rrule
  const rule = rrulestr(schedule.recurrence);
  const recurrenceSection = rruleToRecurrenceSection(rule);
  const duration = schedule.taskPayload.dueDate?.duration;
  const perUser = schedule.taskPayload.formPerUser;
  const startDate = dateOrTimestampAsDate(schedule.startDate);
  const startDateLocal = dayjs(startDate).tz(schedule.timeZone);

  return {
    ...schedule,
    startDate: startDate!.toISOString(),
    frequencySimplified: recurrenceToSimplifiedFreq(startDate!, rule),
    _assignType: schedule.taskPayload.assignedTo?.length
      ? perUser
        ? "user-each"
        : "user-shared"
      : schedule.taskPayload.assignedToRole
        ? perUser
          ? "role-each"
          : "role-shared"
        : "user-shared",
    _dueDate: duration ? "overdue-after" : "none",
    _dueDateDuration: duration ? durationToQuantity(duration) : undefined,
    _dueDateUnit: duration ? durationToUnit(duration) : undefined,
    _recurrence: recurrenceSection,
    _timeOfDay: startDateLocal.toISOString(),
  };
}

export const FrequencySelectOptions = [
  [Frequency.MINUTELY.toString(), "Minute"],
  [Frequency.DAILY.toString(), "Day"],
  [Frequency.WEEKLY.toString(), "Week"],
  [Frequency.MONTHLY.toString(), "Month"],
  // [Frequency.QUARTERLY, "Quarter"],
  [Frequency.YEARLY.toString(), "Year"],
].map((v) => ({
  key: v[0].toString(),
  value: v[0],
  label: v[1] as string,
}));

export const WeekdaySelectOptions = [
  [RRule.MO.weekday.toString(), "Monday"],
  [RRule.TU.weekday.toString(), "Tuesday"],
  [RRule.WE.weekday.toString(), "Wednesday"],
  [RRule.TH.weekday.toString(), "Thursday"],
  [RRule.FR.weekday.toString(), "Friday"],
  [RRule.SA.weekday.toString(), "Saturday"],
  [RRule.SU.weekday.toString(), "Sunday"],
].map((v) => {
  return { key: v[0].toString(), value: v[0], label: v[1] as string };
});

export function isScheduleType(schedule: unknown): schedule is ScheduleType {
  return Boolean(
    isObject(schedule) &&
      schedule.id &&
      schedule.name &&
      schedule.recurrence &&
      schedule.startDate &&
      schedule.pastScheduledRuns,
  );
}

export const testOnly = {
  rruleToRecurrenceSection,
};
