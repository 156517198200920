import { Timestamp } from "@google-cloud/firestore";
import { DateRelative } from "../typings";

import * as Yup from "yup";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const Time = {
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  mins: 60 * 1000,
  seconds: 1000,
  ms: 1,
};

export function nth(d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// h (hours), d (days), w (weeks), m (months), y (years)
type DurationUnits = "h" | "d" | "w" | "m" | "y";

export type Duration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
};

const DATE = Yup.date();

export function isDate(v: unknown): v is Date {
  return DATE.isValidSync(v);
}

const TimestampValidator = Yup.object({
  seconds: Yup.number().required(),
  nanoseconds: Yup.number().required(),
});

export function isTimestamp(value: unknown): value is Timestamp {
  return TimestampValidator.isValidSync(value);
}

export function dateOrTimestampAsDate(
  v?: Date | Timestamp | null,
): Date | undefined | null {
  if (!v) {
    return v;
  }

  if (isTimestamp(v)) {
    return new Timestamp(v.seconds, v.nanoseconds).toDate();
  } else if (typeof v === "string") {
    return new Date(v);
  } else {
    return v;
  }
}

export const RelativeDateRegExp = new RegExp("([0-9]+)([d,w,m,y])");
// export const RelativeDateRegExp = new RegExp("[0-9]+[d,w,m,y]", "g");

// TODO: Move RelativeDateRegExp and Duration here

export function parseRelativeDuration(v: string): Duration | undefined {
  // Extract number and unit
  const matches = v.match(RelativeDateRegExp);
  if (!matches || matches.length < 3) {
    return;
  }
  const numStr = matches[1];
  const unit = matches[2];
  const num = parseInt(numStr);
  switch (unit) {
    case "d":
      return { days: num };
    case "w":
      return { weeks: num };
    case "m":
      return { months: num };
    case "y":
      return { years: num };
    default:
      return undefined;
  }
}

export function dueDateFormToDuration(d: {
  _dueDateDuration?: number;
  _dueDateUnit?: DurationUnits;
}): DateRelative {
  const due: DateRelative = {
    dtType: "relative",
    duration: {},
  };
  switch (d._dueDateUnit) {
    case "h":
      due.duration.hours = d._dueDateDuration;
      break;
    case "d":
      due.duration.days = d._dueDateDuration;
      break;
    case "w":
      due.duration.weeks = d._dueDateDuration;
      break;
    case "m":
      due.duration.months = d._dueDateDuration;
      break;
    case "y":
      due.duration.years = d._dueDateDuration;
      break;
  }
  return due;
}

export function durationToQuantity(d: Duration): number {
  if (d.hours) {
    return d.hours;
  } else if (d.days) {
    return d.days;
  } else if (d.weeks) {
    return d.weeks;
  } else if (d.months) {
    return d.months;
  } else if (d.years) {
    return d.years;
  }
  return 0;
}

export function durationToUnit(d: Duration): DurationUnits | undefined {
  if (d.hours) {
    return "h";
  } else if (d.days) {
    return "d";
  } else if (d.weeks) {
    return "w";
  } else if (d.months) {
    return "m";
  } else if (d.years) {
    return "y";
  }
  return undefined;
}

export function durationToString(d: Duration): string {
  if (d.hours) {
    return d.hours.toString() + "h";
  } else if (d.days) {
    return d.days.toString() + "d";
  } else if (d.weeks) {
    return d.weeks.toString() + "w";
  } else if (d.months) {
    return d.months.toString() + "m";
  } else if (d.years) {
    return d.years.toString() + "y";
  }
  return "";
}

// Extracts any valid relative duration from the string and returns it
export function extractValidRelativeDuration(v: string): string | undefined {
  const str = v as string;
  const match = str.match(RelativeDateRegExp);
  const numMatches = str.match(/[0-9]+/);
  if (match && match.length > 0) {
    // Take the first match and set it if the string is not valid
    return match[0];
  } else if (numMatches) {
    // If not matching the full format, filter only for digits
    if (numMatches) {
      return numMatches[0];
    }
  } else {
    // The input is not a fully valid or partially valid value
    return "";
  }
}

type TimeData = {
  hour: number;
  minute: number;
  second?: number;
  millisecond?: number;
};

const doubleDigitRegex = new RegExp("^[0-9]{2}$");
const tripleDigitRegex = new RegExp("^[0-9]{3}$");

// Parse either a full or partial ISO segments, with the minimum being HH:mm
// Examples: 22:30, T22:30, 22:30:01, 22:30:01.000, 2025-01-17T22:30:01.500Z
export function parseISOTime(time: string): TimeData {
  const tSplit = time.replace("Z", "").split("T");
  const timeParts =
    tSplit.length === 2 ? tSplit[1].split(":") : tSplit[0].split(":");

  const invalidErr = (msg?: string) => {
    msg = msg ? ", " + msg : "";
    return new Error(`Invalid time string: ${time}${msg}`);
  };
  if (timeParts.length < 2 || timeParts.length > 3) {
    throw invalidErr();
  }

  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;
  for (let i = 0; i < timeParts.length; i++) {
    if (i < 2 && !doubleDigitRegex.test(timeParts[i]))
      throw invalidErr("invalid hour or minute");
    switch (i) {
      case 0:
        hours = parseInt(timeParts[i], 10);
        break;
      case 1:
        minutes = parseInt(timeParts[i], 10);
        break;
      case 2: {
        const secMsSplit = timeParts[i].split(".");
        if (!doubleDigitRegex.test(secMsSplit[0]))
          throw invalidErr("invalid second");
        seconds = parseInt(secMsSplit[0], 10);
        if (secMsSplit.length === 2) {
          if (!tripleDigitRegex.test(secMsSplit[1]))
            throw invalidErr("invalid millisecond");
          milliseconds = parseInt(secMsSplit[1], 10);
        }
      }
    }
  }
  if (hours === undefined || minutes === undefined) {
    throw new Error(`Hour (${hours}) or minutes (${minutes}) were not found`);
  }
  return {
    hour: hours,
    minute: minutes,
    second: seconds,
    millisecond: milliseconds,
  };
}

export function timeDataToHHmm(data: TimeData, use12Hour?: boolean) {
  const time = dayjs().hour(data.hour).minute(data.minute);
  return use12Hour ? time.format("hh:mm A") : time.format("HH:mm");
}

// Range where a time can rollover into the next or previous day:
// 23:00 <= t < 1:00
// We can't use startDate because it's only stored for the date component
//  and the time is the time of the schedule creation, not the configured sched time

// Test with UTC system time
// Cases:
// refDt in summer, dt in winter; offset increases
// refDt in winter, dt in summer; offset decreases
// test around nov 3 dst change and march 10 dst change
export function syncBrokenDSTWithWallclockTime(
  startDate: Date, // Time is not necessarily the same as scheduled time, use only the date
  targetDate: Date,
  wallClockHour: number,
  tz: string,
): Date {
  // Normalize to the same timezone, this way we know offset diff is a DST error
  const start = dayjs(startDate).tz(tz);
  const target = dayjs(targetDate).tz(tz);
  const offsetDiff = start.utcOffset() - target.utcOffset();

  if (offsetDiff !== 0) {
    const adjusted = target.add(offsetDiff, "minute");
    if (adjusted.hour() !== wallClockHour) {
      // eslint-disable-next-line no-console
      console.error(
        `Adjusted time (${adjusted.format()}) doesn't match the expected wallclock hour (${wallClockHour})`,
      );
    }
    return adjusted.toDate();
  }

  return targetDate;
}
