import {
  IFieldTypes,
  IField,
  IFieldGroupBuilt,
  IElementTypes,
  IConditionComparisonOperationsEnum,
} from "../../typings";

const fieldOptions = Object.values(IFieldTypes);
const dropDownFieldOptions = Object.values({
  autocomplete: "autocomplete",
  select: "select",
});

const elementOptions = Object.values(IElementTypes).map((key) => ({
  key,
  value: key,
  label: key,
}));

export const hiddenField: IField = {
  name: "hidden",
  label: "Hidden",
  fieldType: IFieldTypes.toggle,
  defaultValue: false,
  span: 2,
};

export const headlessField: IField = {
  name: "headless",
  label: "Headless",
  description: "Hide section header",
  fieldType: IFieldTypes.toggle,
  defaultValue: false,
  span: 2,
};

export const hideBreadCrumbField: IField = {
  name: "hideBreadCrumb",
  label: "Hide Breadcrumb",
  description: "Hide section breadcrumb",
  fieldType: IFieldTypes.toggle,
  defaultValue: false,
  span: 2,
};

export const labelPlacementField: IField = {
  name: "labelPlacement",
  label: "Label Placement",
  fieldType: IFieldTypes.select,
  defaultValue: "inline",
  options: [
    { key: "inline", value: "inline", label: "Inline" },
    { key: "above", value: "above", label: "Above" },
  ],
  span: 2,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [
        IFieldTypes.select,
        IFieldTypes.autocomplete,
        IFieldTypes.textField,
        IFieldTypes.textArea,
        IFieldTypes.dateField,
        IFieldTypes.timeField,
        IFieldTypes.emailField,
        IFieldTypes.fileSelect,
        IFieldTypes.imageSelect,
        IFieldTypes.videoSelect,
        IFieldTypes.numberField,
        IFieldTypes.password,
        IFieldTypes.phoneField,
        IFieldTypes.signatureField,
        IFieldTypes.userSelectField,
        IFieldTypes.documentSelectField,
        IFieldTypes.contractorSelectField,
        IFieldTypes.equipmentSelectField,
      ],
      action: "hidden",
    },
  ],
};

export const elementTypeField: IField = {
  name: "fieldType",
  label: "Element Type",
  fieldType: IFieldTypes.select,
  autoFocus: true,
  options: elementOptions,
  span: 2,
};

export const fieldTypeField: IField = {
  name: "fieldType",
  label: "Field Type",
  fieldType: IFieldTypes.select,
  required: true,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      action: "visible",
      value: [IFieldTypes.autocomplete, IFieldTypes.select],
    },
  ],
  options: fieldOptions.map((key) => ({ key, value: key, label: key })),
  span: 2,
};
export const dropDownType: IField = {
  name: "fieldType",
  label: "Field Type",
  fieldType: IFieldTypes.select,
  required: true,
  readonly: false,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.in,
      value: [IFieldTypes.autocomplete, IFieldTypes.select],
      action: "visible",
    },
  ],
  options: dropDownFieldOptions.map((key) => ({ key, value: key, label: key })),
  span: 2,
};

export const fieldLabelField: IField = {
  fieldType: IFieldTypes.textField,
  name: "label",
  label: "Field Label",
  autoFocus: true,
  required: true,
  span: 2,
};

export const requiredField: IField = {
  fieldType: IFieldTypes.toggle,
  label: "Required",
  name: "required",
  defaultValue: false,
  span: 2,
};

export const readonlyField: IField = {
  fieldType: IFieldTypes.toggle,
  label: "Readonly",
  name: "readonly",
  defaultValue: false,
  span: 2,
};

export const autofocusField: IField = {
  fieldType: IFieldTypes.toggle,
  label: "Auto Focus",
  name: "autoFocus",
  defaultValue: false,
  span: 2,
};

export const freeSoloField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [IFieldTypes.autocomplete],
      action: "hidden",
    },
  ],
  label: "Free Form",
  name: "freeSolo",
  description: "Allow new values to be submitted into the autocomplete field.",
  span: 2,
};

export const systemNameField: IField = {
  fieldType: IFieldTypes.textField,
  name: "name",
  label: "System Name",
  description:
    // eslint-disable-next-line prettier/prettier
    "System names are used internally to aggregate data entered into the field. e.g., \"Area of Injury\"",
  span: 2,
};

export const multipleField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [
        IFieldTypes.select,
        IFieldTypes.userSelectField,
        IFieldTypes.contractorSelectField,
        IFieldTypes.equipmentSelectField,
        IFieldTypes.documentSelectField,
        IFieldTypes.autocomplete,
        IFieldTypes.fileSelect,
        IFieldTypes.imageSelect,
      ],
      action: "hidden",
    },
  ],
  label: "Multiple",
  name: "multiple",
  span: 4,
};
export const descriptionField: IField = {
  fieldType: IFieldTypes.textArea,
  name: "description",
  label: "Description",
  max: 5000,
  defaultValue: "",
  span: 4,
};
export const minField: IField = {
  fieldType: IFieldTypes.numberField,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [
        IFieldTypes.autocomplete,
        IFieldTypes.select,
        IFieldTypes.checkbox,
        IFieldTypes.password,
        IFieldTypes.textArea,
        IFieldTypes.textField,
      ],
      action: "hidden",
    },
  ],
  label: "Min",
  name: "min",
  description: "Minimum length of input value",
  span: 2,
};

export const maxField: IField = {
  fieldType: IFieldTypes.numberField,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [
        IFieldTypes.autocomplete,
        IFieldTypes.select,
        IFieldTypes.checkbox,
        IFieldTypes.password,
        IFieldTypes.textArea,
        IFieldTypes.textField,
      ],
      action: "hidden",
    },
  ],
  label: "Max",
  name: "max",
  description: "Maximum length of input value",
  span: 2,
};

export const allowCommentsField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.eq,
      value: IFieldTypes.inspectionQuestion,
      action: "visible",
    },
  ],
  label: "Allow Comment",
  name: "allowComment",
  span: 2,
};

export const allowFileField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.eq,
      value: IFieldTypes.inspectionQuestion,
      action: "visible",
    },
  ],
  label: "Allow File Attachments",
  name: "allowFile",
  span: 2,
};

export const allowActionCreationField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.eq,
      value: IFieldTypes.inspectionQuestion,
      action: "visible",
    },
  ],
  label: "Allow Action Creation",
  name: "allowActionCreation",
  span: 2,
};

export const spanField: IField = {
  fieldType: IFieldTypes.select,
  label: "Column Span",
  name: "span",
  description: "Number of columns the field will span.",
  defaultValue: 2,
  options: [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ],
  span: 2,
};

export const autoFillNowField: IField = {
  fieldType: IFieldTypes.toggle,
  label: "Autofill Now",
  name: "autofillNow",
  description: "Autofills with the current time or date while filling the form",
  defaultValue: false,
  span: 2,
};

export const xyMatrixOptionsMap: IFieldGroupBuilt = {
  name: "options",
  label: "Options",
  min: 1,
  max: 999,
  fields: [
    {
      name: "key",
      label: "Key",
      required: true,
      fieldType: IFieldTypes.textField,
      span: 1,
    },
    {
      name: "label",
      label: "Label",
      required: true,
      fieldType: IFieldTypes.textField,
      span: 1,
    },
    {
      name: "description",
      label: "Description",
      required: true,
      fieldType: IFieldTypes.textArea,
      span: 1,
    },
  ],
};

export const optionsGroup: IFieldGroupBuilt = {
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.notin,
      value: [IFieldTypes.select, IFieldTypes.radio, IFieldTypes.autocomplete],
      action: "hidden",
    },
  ],
  name: "options",
  label: "Options",
  min: 1,
  max: 999,
  fields: [
    {
      name: "value",
      label: "Value",
      required: true,
      fieldType: IFieldTypes.textField,
      max: 122,
    },
    {
      name: "label",
      label: "Label",
      fieldType: IFieldTypes.textField,
    },
  ],
};

export const colorOptionsGroup: IFieldGroupBuilt = {
  name: "options",
  label: "Options",
  min: 1,
  max: 999,
  fields: [
    {
      name: "value",
      label: "Value",
      required: true,
      fieldType: IFieldTypes.textField,
      max: 122,
    },
    {
      name: "label",
      label: "Label",
      fieldType: IFieldTypes.textField,
    },
    {
      name: "meta.color",
      label: "Option Color",
      inline: true,
      fieldType: IFieldTypes.colorField,
    },
  ],
};
export const textFieldField: IField = {
  fieldType: IFieldTypes.textArea,
  name: "text",
  label: "Text",
  max: 10000,
  defaultValue: "",
  span: 4,
};

export const notificationDisplayField: IField = {
  fieldType: IFieldTypes.toggle,
  name: "meta.displayInNotification",
  label: "Display in email notifications",
  description:
    "Show the value of this field in the email notifications for this entity",
  span: 4,
};

export const includeInactiveUsersField: IField = {
  fieldType: IFieldTypes.toggle,
  conditions: [
    {
      parameter: ".fieldType",
      operation: IConditionComparisonOperationsEnum.eq,
      value: IFieldTypes.userSelectField,
      action: "visible",
    },
  ],
  label: "Show Inactive Users",
  name: "includeInactive",
  span: 2,
};

export const FIELD_FORM: IFieldGroupBuilt = {
  fields: [
    fieldTypeField,
    fieldLabelField,
    systemNameField,
    requiredField,
    readonlyField,
    hiddenField,
    autofocusField,
    freeSoloField,
    allowFileField,
    allowCommentsField,
    allowActionCreationField,
    labelPlacementField,
    multipleField,
    includeInactiveUsersField,
    descriptionField,
    minField,
    maxField,
    spanField,
  ],
  groups: [optionsGroup],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const FIELD_REFERENCE_FORM: IFieldGroupBuilt = {
  fields: [
    fieldLabelField,
    { ...systemNameField, readonly: true },
    {
      name: "fieldId",
      label: "fieldId",
      readonly: true,
      fieldType: IFieldTypes.textField,
      span: 2,
    },
  ],
  groups: [],
  min: 1,
  max: 1,
  label: "Field Reference",
  name: "fieldReference",
};

export const FIELD_GROUP_REFERENCE_FORM: IFieldGroupBuilt = {
  fields: [
    fieldLabelField,
    { ...systemNameField, readonly: true },
    {
      name: "groupId",
      label: "groupId",
      readonly: true,
      fieldType: IFieldTypes.textField,
      span: 2,
    },
  ],
  groups: [],
  min: 1,
  max: 1,
  label: "Field Group Reference",
  name: "fieldGroupReference",
};

export const UPDATE_SUB_FORM_FIELD: IFieldGroupBuilt = {
  fields: [
    {
      ...fieldTypeField,
      readonly: true,
    },
    systemNameField,
    { ...fieldLabelField, label: "Sub Form / Corrective Action Label" },
    // descriptionField,
    {
      ...minField,
      conditions: [],
      description: "Minimum number of entries",
    },
    {
      ...maxField,
      conditions: [],
      description: "Maximum number of entries",
    },
  ],
  groups: [],
  min: 1,
  max: 1,
  label: "Sub Form / Corrective Action",
  name: "childSubmissionForm",
};

export const UPDATE_FIELD_FORM: IFieldGroupBuilt = {
  fields: [
    fieldLabelField,
    requiredField,
    readonlyField,
    autofocusField,
    hiddenField,
    notificationDisplayField,
    freeSoloField,
    allowFileField,
    allowCommentsField,
    allowActionCreationField,
    labelPlacementField,
    multipleField,
    includeInactiveUsersField,
    descriptionField,
    minField,
    maxField,
    spanField,
    systemNameField,
    {
      ...fieldTypeField,
      readonly: true,
    },
  ],
  groups: [optionsGroup],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const UPDATE_DATE_FIELD_FORM: IFieldGroupBuilt = {
  ...UPDATE_FIELD_FORM,
  fields: [...UPDATE_FIELD_FORM.fields!, autoFillNowField],
};

export const UPDATE_VIDEO_PLAYER_FORM: IFieldGroupBuilt = {
  fields: [
    {
      ...fieldLabelField,
      span: 4,
    },
    // {
    //   fieldType: IFieldTypes.fileSelect,
    //   description: "Source file url",
    //   label: "Source Video File",
    //   name: "videoFile",
    //   span: 4,
    //   required: true
    // },
    {
      name: "playerConfig.allowPause",
      label: "Allow Pause",
      fieldType: IFieldTypes.toggle,
      disabled: true,
      hidden: true,
      defaultValue: true,
      span: 2,
    },
    {
      name: "playerConfig.allowRewind",
      label: "Allow Rewind",
      fieldType: IFieldTypes.toggle,
      disabled: true,
      hidden: true,
      defaultValue: true,
      span: 2,
    },
    {
      name: "playerConfig.allowPlaybackSpeed",
      label: "Allow Playback Speeds",
      fieldType: IFieldTypes.toggle,
      disabled: true,
      hidden: true,
      defaultValue: true,
      span: 2,
    },
    {
      name: "playerConfig.allowFastForward",
      label: "Allow Fast Forward",
      fieldType: IFieldTypes.toggle,
      disabled: true,
      hidden: true,
      defaultValue: true,
      span: 2,
    },
    {
      name: "playerConfig.allowCloseCaption",
      label: "Enable Close Captions",
      fieldType: IFieldTypes.toggle,
      defaultValue: false,
      disabled: true,
      hidden: true,
      span: 2,
    },
    {
      name: "playerConfig.allowChangeQuality",
      label: "Allow Change Quality",
      fieldType: IFieldTypes.toggle,
      defaultValue: false,
      disabled: true,
      hidden: true,
      span: 2,
    },
    {
      name: "playerConfig.allowOfflinePlay",
      label: "Allow Offline Play",
      fieldType: IFieldTypes.toggle,
      defaultValue: false,
      disabled: true,
      hidden: true,
      span: 2,
    },
    {
      ...requiredField,
      span: 2,
    },
    {
      ...hiddenField,
      span: 2,
    },
    descriptionField,
    {
      ...spanField,
      span: 1,
      disabled: true,
      hidden: true,
      defaultValue: 4,
    },
    {
      ...systemNameField,
      disabled: true,
      hidden: true,
      span: 2,
    },
  ],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const UPDATE_MATRIX_FIELD_FORM: IFieldGroupBuilt = {
  fields: [
    fieldLabelField,
    {
      fieldType: IFieldTypes.textField,
      name: "xLabel",
      label: "X Axis Label",
      required: true,
      span: 1,
    },
    {
      fieldType: IFieldTypes.textField,
      name: "yLabel",
      label: "Y Axis Label",
      required: true,
      span: 1,
    },
    {
      ...requiredField,
      span: 1,
    },
    {
      ...readonlyField,
      span: 1,
    },
    {
      ...autofocusField,
      span: 1,
    },
    {
      ...hiddenField,
      span: 1,
    },
    notificationDisplayField,
    descriptionField,
    {
      ...spanField,
      span: 1,
    },
    {
      ...systemNameField,
      span: 1,
    },
  ],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const UPDATE_AUTOCOMPLETE_FIELD_FORM: IFieldGroupBuilt = {
  fields: [
    fieldLabelField,
    requiredField,
    readonlyField,
    autofocusField,
    hiddenField,
    notificationDisplayField,
    freeSoloField,
    allowFileField,
    allowCommentsField,
    allowActionCreationField,
    labelPlacementField,
    multipleField,
    descriptionField,
    minField,
    maxField,
    spanField,
    systemNameField,
    dropDownType,
  ],
  groups: [optionsGroup],
  min: 1,
  max: 1,
  label: "Field",
  name: "Field",
};

export const UPDATE_ELEMENT_FORM: IFieldGroupBuilt = {
  fields: [elementTypeField, textFieldField, spanField, systemNameField],
  groups: [],
  min: 1,
  max: 1,
  label: "Element",
  name: "Element",
};

export const UPDATE_TEXT_EDITOR_FORM: IFieldGroupBuilt = {
  fields: [hiddenField],
  groups: [],
  min: 1,
  max: 1,
  label: "Element",
  name: "Element",
};

export const UPDATE_DIVIDER_FORM: IFieldGroupBuilt = {
  fields: [
    {
      ...elementTypeField,
      readonly: true,
    },
    textFieldField,
    spanField,
    systemNameField,
  ],
  groups: [],
  min: 1,
  max: 1,
  label: "Element",
  name: "Element",
};

export const UPDATE_HEADER_FORM: IFieldGroupBuilt = {
  min: 1,
  max: 1,
  label: "Element",
  name: "Element",
  fields: [
    elementTypeField,
    {
      name: "meta.headerType",
      label: "Header",
      fieldType: IFieldTypes.select,
      autoFocus: true,
      options: ["h1", "h2", "h3"].map((key) => ({
        key,
        value: key,
        label: key,
      })),
      span: 2,
    },
    textFieldField,
    spanField,
    systemNameField,
  ],
  groups: [],
};
