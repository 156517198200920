import { UserReferenceEntity } from "../users";
import {
  IFieldGroup,
  IFieldGroupBuilt,
  IFieldGroupReference,
  isFieldGroup,
} from "./field-group";
import {
  CommentProperties,
  AttachmentProperties,
  IObjectLink,
  IFileReference,
  ICommentEntity,
} from "../objects";
import { Entity } from "../generics";
import { isObject } from "../../utilities";
import { QueueProperties } from "../queue";
import { StateAssigneeConfig, WorkflowProperties } from "../workflow";
import { NotificationSchema } from "../notification";
import { SchemaReference } from "../schema";

// eslint-disable-next-line no-shadow
export enum IObjectType {
  "form" = "form",
  "task" = "task",
  "asset" = "asset",
}

// eslint-disable-next-line no-shadow
export enum ITaskSubTypes {
  "corrective-action" = "corrective-action",
  "issue" = "issue",
}

export interface ISection<T = IFieldGroup | IFieldGroupReference> {
  [s: string]: T | undefined;
  default?: T;
}

/**
 * On Object is a bit of config that defines a process, form, jsa, etc.
 * For the task, its the reusable structure thats created to define what a 'task' is. It is not a specific task
 * For a form, its the list of fields that needs to be filled out.
 * JSA, is just like a supped up form.
 *
 * See object-example for an example of the intended use of an ObjectTemplate
 */
export interface IObjectConfig<G = IFieldGroup | IFieldGroupReference>
  extends CommentProperties,
    AttachmentProperties,
    Omit<WorkflowProperties, "state"> {
  // Object system information
  name: string;
  type: IObjectType;
  subType?: ITaskSubTypes;

  // metadata
  title: string;
  description: string;
  category: string[];
  tags: string[];

  // todo: migrate metadata to within this section
  metadata?: {
    /**
     * This means the corrective action can only be created from within the form
     */
    correctiveActionFormOnly?: boolean;
    [props: string]: unknown;
  };

  // section
  sections: ISection<G>;

  // TODO: this could be replaced with an something like "automation rules", that jira has.
  // track what auto-assignment based of form state
  // - this need to handle supervisor re-assignment
  // - this needs to preserve previous assignee state so that reversion will work
  // - on initial creation set first state uids to be assignees passed in from form, or the created by user
  // - what should adding assignee in the middle of state do? it should update the assignees map. not the stateAssigneeMap.
  //          unless your editing the object while its in a state with `assignee` true.
  //          then all states with `assignee` will be synced

  stateAssigneeMap?: {
    // auto assignee to these people when state is transitioned to this `objectState`.
    // assignee: true => editing activeUsers will update assignees
    // supervisor: true => assign to supervisors of the last state that was configured with assignee boolean

    [objectState: string]: undefined | StateAssigneeConfig;
  };

  /**
   * section ordering is done by sectionIdx
   */
  ordering?: string[];

  // SLA (dates)
  // TODO: Implement when dueDate
  slaSchema: Record<string, SLA>;

  /**
   * TODO: Recurring event is hard see, when implementing recurring events.
   * https://datatracker.ietf.org/doc/html/rfc5545
   * https://en.wikipedia.org/wiki/ICalendar
   * https://www.kanzaki.com/docs/ical/rrule.html
   * ISO-8601
   */
  datesSchema: unknown;

  notificationSchemaRef?: {
    schemaId: string;
    schemaVersion: number;
  };
  notificationSchema?: NotificationSchema;
}

// man would it be great to be able to convert the IFieldGroup into a Type
export interface IObjectInstance<T = object | undefined>
  extends IObjectConfig<IFieldGroupBuilt>,
    QueueProperties,
    Entity {
  /**
   * who's to-do list this will show up in
   * - this can be computed based on the state and the assigneesMap but
   */
  activeUsers?: Record<string, UserReferenceEntity>;

  owners: Record<string, UserReferenceEntity>;

  /**
   * This is a map of user assignee references based on state,
   * {
   *   [ stateKey ] : {
   *      [ userId ]: UserRef
   *   }
   * }
   */
  assigneesByStateMap: Record<string, Record<string, UserReferenceEntity>>;

  /**
   * @deprecated
   * TODO: this can be renamed to "owners" to represent owner of the item (this is user in training and CA?)
   * TRANSFORM: (rename) -> owners
   *
   * contextually who's responsible for this items
   */
  assignees?: Record<string, UserReferenceEntity>;

  /**
   * @deprecated
   * TODO: I think this can be removed from the system
   * TRANSFORM: (delete)
   *
   * who is responsible for managing it
   */
  reporters?: Record<string, UserReferenceEntity>;

  /**
   * who wants to get the 'watchers' notification
   */
  watcherIds: string[];

  createdBy?: {
    uid?: string;
  };

  meta?: {
    title?: string;
    dueDate?: number;
    publicSubmission?: boolean;
    publicSubmitterInfo?: unknown;
    scheduleId?: string;
    scheduleTaskId?: string;
    scheduleDate?: number;
    assignedToRole?: string;
    scheduleName?: string;
    [key: string]: unknown;
  };

  state: string; // workflow property omitted in ObjectConfig type
  data: T;
  links: Record<string, IObjectLink>;
  schemaRef: {
    schemaId: string;
    schemaVersion: number;
  };
}

export type ElasticObjectInstanceEntity<T = unknown> = Omit<
  IObjectInstance<T>,
  | "owners"
  | "assigneesByStateMap"
  | "assignees"
  | "reporters"
  | "links"
  | "attachments"
  | "comments"
  | "activeUsers"
> & {
  owners: UserReferenceEntity[];
  activeUsers: UserReferenceEntity[];
  assigneesByStateMap: Record<string, UserReferenceEntity[]>;
  attachments: IFileReference[];
  comments: ICommentEntity[];
  links: IObjectLink[];
  /** @deprecated */
  assignees: UserReferenceEntity[];
  /** @deprecated */
  reporters: UserReferenceEntity[];
};

export interface ObjectInstanceReference {
  id: string;
  schemaId: string;
  type: IObjectType | ITaskSubTypes;
  state: string;
  stateName: string;
  updatedAt?: number;
  createdAt?: number;
  activeUserIds?: string[];
}

export type AllObjectReferenceMetadata = Pick<
  IObjectConfig,
  | "name"
  | "description"
  | "category"
  | "metadata"
  | "tags"
  | "type"
  | "subType"
  | "title"
>;

export type AllObjectsReference = SchemaReference<
  AllObjectReferenceMetadata,
  AllObjectReferenceMetadata
> &
  Entity;

export interface SLA {
  name: string;
  seconds: number;
  calendar: string;
  notification: NotificationTargets;
}

// eslint-disable-next-line no-shadow
export enum NotificationRates {
  hourly = 60,
  daily = 60 * 24,
  once = -1,
}
export interface NotificationTargets {
  rate: NotificationRates;
  target: "user" | "group" | "profile" | "role";
  targetIds: string[];
}

export const isSection = (obj: unknown): obj is ISection => {
  return isObject(obj) && Object.values(obj).every((sec) => isFieldGroup(sec));
};

export function isObjectConfig(obj: unknown): obj is IObjectConfig {
  return Boolean(isObject(obj) && isSection(obj.sections) && obj.name);
}
